import { useAdminLogout } from './_useAdminLogout';
import { useAdminOAuthLogin } from './_useAdminOauthLogin';
import { useAdminPasswordLogin } from './_useAdminPasswordLogin';

export const AdminAuthHooks = {
  useAdminPasswordLogin: useAdminPasswordLogin.uncontrolled,
  useAdminOAuthLogin: useAdminOAuthLogin.uncontrolled,
  useAdminLogout: useAdminLogout.uncontrolled,
  controlled: {
    useAdminPasswordLogin: useAdminPasswordLogin.controlled,
    useAdminOAuthLogin: useAdminOAuthLogin.controlled
  }
};
