import { AdministratorSession } from '@/admin/models/AdministratorSession';
import {
  AsyncActionHandler,
  useActionHandler,
  UseHandleActionOptions
} from '@/common/hooks/useActionHandler';

import { AdminAuthActions } from '../_actions';
import { AdministratorLoginRequest } from '../_types';

interface HandleOverrides
  extends UseHandleActionOptions<AdministratorSession> {}

interface HandleConfig extends HandleOverrides {
  onRouterPush: (path: string) => void;
  handler: AsyncActionHandler;
}

const useControlled = (config: HandleConfig) => handleLoginAsync(config);

const useUncontrolled = (
  options: Pick<HandleConfig, 'onRouterPush'> & HandleOverrides
) => {
  const [handler, { isHandling }] = useActionHandler();
  const controlledOptions = (options || {}) as any as HandleConfig;
  controlledOptions.handler = handler;
  const handleAsync = useControlled(controlledOptions);

  return [handleAsync, isHandling] as const;
};

const handleLoginAsync = ({
  handler,
  onRouterPush,
  source = 'ADMIN_LOGIN',
  onSuccess: configOnSuccess,
  ...configRest
}: HandleConfig) => {
  return (
    {
      email,
      password,
      companyId,
      redirectUrl
    }: AdministratorLoginRequest & { redirectUrl?: string },
    options?: Pick<HandleOverrides, 'onSuccess'>
  ) =>
    handler(() => AdminAuthActions.loginAsync({ email, password, companyId }), {
      onSuccess: (session) => {
        AdminAuthActions.loginRedirectAndSession(
          session,
          onRouterPush,
          redirectUrl
        );
        configOnSuccess?.(session);
        options?.onSuccess?.(session);
      },
      source,
      ...configRest
    });
};

export const useAdminPasswordLogin = {
  uncontrolled: useUncontrolled,
  controlled: useControlled
};
