import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { AdminWorkspaceService } from '@/admin/data/Workspaces';
import {
  AsyncActionHandler,
  useActionHandler,
  UseHandleActionOptions
} from '@/common/hooks/useActionHandler';
import { AdminWorkspace } from '@/common/models/workspaces/Admin/AdminWorkspace';

import { AdminWorkspaceActions } from '../_actions';
import { AdminWorkspaceUpdateRequest } from '../_types';

export type WorkspaceUpdateHookAction = (
  request: AdminWorkspaceUpdateRequest
) => Promise<AdminWorkspace>;

type HandleOverrides = UseHandleActionOptions<AdminWorkspace>;
interface HandleConfig extends HandleOverrides {
  handler: AsyncActionHandler;
  queryClient: QueryClient;
}

const useControlled = (config: HandleConfig) => {
  return useCallback<WorkspaceUpdateHookAction>(handleUpdateAsync(config), [
    config.queryClient
  ]);
};

const useUncontrolled = (options?: HandleOverrides) => {
  const [handler, { isHandling }] = useActionHandler();
  const queryClient = useQueryClient();

  const controlledOptions = (options || {}) as any as HandleConfig;
  controlledOptions.handler = handler;
  controlledOptions.queryClient = queryClient;

  const handleAsync = useControlled(controlledOptions);

  return [handleAsync, isHandling] as [WorkspaceUpdateHookAction, boolean];
};

const handleUpdateAsync = (config: HandleConfig) => {
  return (request: AdminWorkspaceUpdateRequest) => {
    return config.handler(() => AdminWorkspaceActions.updateAsync(request), {
      source: 'WORKSPACE_UPDATE',
      throwOnError: true,
      noToastOnError: true,
      onSuccess: (workspace) => {
        config.queryClient.setQueryData(
          AdminWorkspaceService.cacheKeys.get(workspace.id),
          workspace
        );
      }
    });
  };
};

export const useAdminWorkspaceUpdate = {
  uncontrolled: useUncontrolled,
  controlled: useControlled
};
