import {
  AsyncActionHandler,
  useActionHandler,
  UseHandleActionOptions
} from '@/common/hooks/useActionHandler';

import { AdminAuthActions } from '../_actions';

interface HandleOverrides extends UseHandleActionOptions {}

interface HandleConfig extends HandleOverrides {
  onRouterPush: (path: string) => void;
  handler: AsyncActionHandler;
}

const useControlled = (config: HandleConfig) => handleLogoutAsync(config);

const useUncontrolled = (
  options: Pick<HandleConfig, 'onRouterPush'> & HandleOverrides
) => {
  const [handler, { isHandling }] = useActionHandler();
  const controlledOptions = (options || {}) as any as HandleConfig;
  controlledOptions.handler = handler;
  const handleAsync = useControlled(controlledOptions);

  return [handleAsync, isHandling] as const;
};

const handleLogoutAsync = ({
  handler,
  source = 'ADMIN_LOGOUT',
  onSuccess: configOnSuccess,
  noLogOnError = true,
  noToastOnError = true,
  onRouterPush,
  onError,
  ...configRest
}: HandleConfig) => {
  return (options?: Pick<HandleOverrides, 'onSuccess'>) =>
    handler(() => AdminAuthActions.logoutAsync(), {
      onSuccess: () => {
        onRouterPush('/portal/auth/login');
        configOnSuccess?.();
        options?.onSuccess?.();
      },
      onError: () => {
        onRouterPush('/portal/auth/login');
        onError?.();
      },
      source,
      noLogOnError,
      noToastOnError,
      ...configRest
    });
};

export const useAdminLogout = {
  uncontrolled: useUncontrolled,
  controlled: useControlled
};
