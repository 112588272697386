import { forwardRef } from 'react';

import { AmondoCardIcon } from '@/common/components/Icons/AmondoCardIcon';
import { AppleMusicIcon } from '@/common/components/Icons/AppleMusicIcon';
import { CarouselIcon } from '@/common/components/Icons/CarouselIcon';
import { ChecklistIcon } from '@/common/components/Icons/ChecklistIcon';
import { ClickBannerIcon } from '@/common/components/Icons/ClickBannerIcon';
import { CountdownIcon } from '@/common/components/Icons/CountdownIcon';
import { ErrorIcon } from '@/common/components/Icons/ErrorIcon';
import { FacebookCardIcon } from '@/common/components/Icons/FacebookCardIcon';
import { FacebookWatchVideoIcon } from '@/common/components/Icons/FacebookWatchVideoIcon';
import { FactIcon } from '@/common/components/Icons/FactIcon';
import { GoogleMapsCardIcon } from '@/common/components/Icons/GoogleMapsCardIcon';
import { HtmlIcon } from '@/common/components/Icons/HtmlIcon';
import { IconProps } from '@/common/components/Icons/IconProps';
import { IframeIcon } from '@/common/components/Icons/IframeIcon';
import { ImageButtonIcon } from '@/common/components/Icons/ImageButtonIcon';
import { ImageIcon } from '@/common/components/Icons/ImageIcon';
import { InstagramCardIcon } from '@/common/components/Icons/InstagramCardIcon';
import { IssuuCardIcon } from '@/common/components/Icons/IssuuCardIcon';
import { LeaderboardIcon } from '@/common/components/Icons/LeaderboardIcon';
import { LinkedInCardIcon } from '@/common/components/Icons/LinkedInCardIcon';
import { LiveSurveyIcon } from '@/common/components/Icons/LiveSurveyIcon';
import { MatterportCardIcon } from '@/common/components/Icons/MatterportCardIcon';
import { MemeGeneratorIcon } from '@/common/components/Icons/MemeGeneratorIcon';
import { MemoryChallengeIcon } from '@/common/components/Icons/MemoryChallengeIcon';
import { MultiPollIcon } from '@/common/components/Icons/MultiPollIcon';
import { NumberGuessCardIcon } from '@/common/components/Icons/NumberGuessCardIcon';
import { PersonalityQuizIcon } from '@/common/components/Icons/PersonalityQuizIcon';
import { PickerIcon } from '@/common/components/Icons/PickerIcon';
import { PollIcon } from '@/common/components/Icons/PollIcon';
import { PredictiveIcon } from '@/common/components/Icons/PredictiveIcon';
import { QuizIcon } from '@/common/components/Icons/QuizIcon';
import { ReceiptUploaderIcon } from '@/common/components/Icons/ReceiptUploaderIcon';
import { ScratchieIcon } from '@/common/components/Icons/ScratchieIcon';
import { SecretCodeIcon } from '@/common/components/Icons/SecretCodeIcon';
import { SpinToWinIcon } from '@/common/components/Icons/SpinToWinIcon';
import { SpotifyIcon } from '@/common/components/Icons/SpotifyIcon';
import { SwiperIcon } from '@/common/components/Icons/SwiperIcon';
import { TikTokCardIcon } from '@/common/components/Icons/TikTokCardIcon';
import { TreasureHuntIcon } from '@/common/components/Icons/TreasureHuntIcon';
import { TriviaIcon } from '@/common/components/Icons/TriviaIcon';
import { TwitchIcon } from '@/common/components/Icons/TwitchIcon';
import { TwitterCardIcon } from '@/common/components/Icons/TwitterCardIcon';
import { UploadIcon } from '@/common/components/Icons/UploadIcon';
import { VideoIcon } from '@/common/components/Icons/VideoIcon';
import { VimeoIcon } from '@/common/components/Icons/VimeoIcon';
import { VoteCardIcon } from '@/common/components/Icons/VoteCardIcon';
import { WatchToWinIcon } from '@/common/components/Icons/WatchToWinIcon';
import { WordsOrLessIcon } from '@/common/components/Icons/WordsOrLessIcon';
import { YoutubeIcon } from '@/common/components/Icons/YoutubeIcon';
import { ThemeIcon, ThemeIconProps } from '@/common/components/ThemeIcon';
import { CardSubTypes } from '@/common/models/CardSubTypes';
import { CardTypes } from '@/common/models/CardTypes';

interface Props extends IconProps {
  type: CardTypes;
  subType?: CardSubTypes;
}

export const CardIcon = forwardRef<HTMLSpanElement, Props>(
  ({ type, subType, ...rest }, ref) => {
    switch (type) {
      case CardTypes.Trivia:
        return <TriviaIcon ref={ref} {...rest} />;
      case CardTypes.NumberGuess:
        return <NumberGuessCardIcon ref={ref} {...rest} />;
      case CardTypes.Quiz:
        if (!subType) return <QuizIcon ref={ref} {...rest} />;
        switch (subType) {
          case CardSubTypes.Personality:
            return <PersonalityQuizIcon ref={ref} {...rest} />;
          case CardSubTypes.Poll:
            return <PollIcon ref={ref} {...rest} />;
          case CardSubTypes.MultiPoll:
            return <MultiPollIcon ref={ref} {...rest} />;
          case CardSubTypes.Quiz:
          default:
            return <QuizIcon ref={ref} {...rest} />;
        }
      case CardTypes.Checklist:
        return <ChecklistIcon ref={ref} {...rest} />;
      case CardTypes.Video:
        if (!subType) return <VideoIcon ref={ref} {...rest} />;
        switch (subType) {
          case CardSubTypes.YouTube:
            return <YoutubeIcon ref={ref} {...rest} />;
          case CardSubTypes.Vimeo:
            return <VimeoIcon ref={ref} {...rest} />;
          case CardSubTypes.Twitch:
            return <TwitchIcon ref={ref} {...rest} />;
          case CardSubTypes.FacebookWatch:
            return <FacebookWatchVideoIcon ref={ref} {...rest} />;
          default:
            return <VideoIcon ref={ref} {...rest} />;
        }
      case CardTypes.Embed:
        if (!subType) return <IframeIcon ref={ref} {...rest} />;
        switch (subType) {
          case CardSubTypes.Spotify:
            return <SpotifyIcon ref={ref} {...rest} />;
          case CardSubTypes.AppleMusic:
            return <AppleMusicIcon ref={ref} {...rest} />;
          case CardSubTypes.Iframe:
            return <IframeIcon ref={ref} {...rest} />;
          case CardSubTypes.Html:
            return <HtmlIcon ref={ref} {...rest} />;
          case CardSubTypes.TikTok:
            return <TikTokCardIcon ref={ref} {...rest} />;
          case CardSubTypes.Twitter:
            return <TwitterCardIcon ref={ref} {...rest} />;
          case CardSubTypes.Instagram:
            return <InstagramCardIcon ref={ref} {...rest} />;
          case CardSubTypes.Facebook:
            return <FacebookCardIcon ref={ref} {...rest} />;
          case CardSubTypes.GoogleMaps:
            return <GoogleMapsCardIcon ref={ref} {...rest} />;
          case CardSubTypes.Issuu:
            return <IssuuCardIcon ref={ref} {...rest} />;
          case CardSubTypes.Matterport:
            return <MatterportCardIcon ref={ref} {...rest} />;
          case CardSubTypes.LinkedIn:
            return <LinkedInCardIcon ref={ref} {...rest} />;
          case CardSubTypes.Amondo:
            return <AmondoCardIcon ref={ref} {...rest} />;
          default:
            return <IframeIcon ref={ref} {...rest} />;
        }
      case CardTypes.Image:
        if (!subType) return <ImageIcon ref={ref} {...rest} />;
        switch (subType) {
          case CardSubTypes.WordsOrLess:
            return <WordsOrLessIcon ref={ref} {...rest} />;
          case CardSubTypes.SecretCode:
            return <SecretCodeIcon ref={ref} {...rest} />;
          case CardSubTypes.ReceiptUploader:
            return <ReceiptUploaderIcon ref={ref} {...rest} />;
          case CardSubTypes.ClickBanner:
            return <ClickBannerIcon ref={ref} {...rest} />;
          case CardSubTypes.ButtonImage:
            return <ImageButtonIcon ref={ref} {...rest} />;
          default:
            return <ImageIcon ref={ref} {...rest} />;
        }
      case CardTypes.Countdown:
        return <CountdownIcon ref={ref} {...rest} />;
      case CardTypes.Leaderboard:
        return <LeaderboardIcon ref={ref} {...rest} />;
      case CardTypes.Predictive:
        return <PredictiveIcon ref={ref} {...rest} />;
      case CardTypes.Canvas:
        return <MemeGeneratorIcon ref={ref} {...rest} />;
      case CardTypes.Upload:
        return <UploadIcon ref={ref} {...rest} />;
      case CardTypes.ScratchAndWin:
        return <ScratchieIcon ref={ref} {...rest} />;
      case CardTypes.Carousel:
        if (!subType) return <CarouselIcon ref={ref} {...rest} />;
        switch (subType) {
          case CardSubTypes.Vote:
            return <VoteCardIcon ref={ref} {...rest} />;
          default:
            return <CarouselIcon ref={ref} {...rest} />;
        }
      case CardTypes.InstantWin:
        return <WatchToWinIcon ref={ref} {...rest} />;
      case CardTypes.Fact:
        return <FactIcon ref={ref} {...rest} />;
      case CardTypes.Picker:
        return <PickerIcon ref={ref} {...rest} />;
      case CardTypes.Swiper:
        return <SwiperIcon ref={ref} {...rest} />;
      case CardTypes.MemoryChallenge:
        return <MemoryChallengeIcon ref={ref} {...rest} />;
      case CardTypes.SpinToWin:
        return <SpinToWinIcon ref={ref} {...rest} />;
      case CardTypes.LiveSurvey:
        return <LiveSurveyIcon ref={ref} {...rest} />;
      case CardTypes.TreasureHunt:
        return <TreasureHuntIcon ref={ref} {...rest} />;
      default:
        const unsupported: never = type;
        console.error('Unsupported card type', unsupported);
        return <ErrorIcon {...rest} />;
    }
  }
);

interface CardThemeIconProps extends Omit<ThemeIconProps, 'color' | 'variant'> {
  type: CardTypes;
  subType?: CardSubTypes;
}
export const CardThemeIcon = forwardRef<HTMLDivElement, CardThemeIconProps>(
  ({ type, subType, size = 'sm', ...rest }, ref) => {
    return (
      <ThemeIcon
        ref={ref}
        variant="light"
        size={size}
        color={resolveColor(type)}
        {...rest}
      >
        <CardIcon type={type} subType={subType} />
      </ThemeIcon>
    );
  }
);

const resolveColor = (type: CardTypes): ThemeIconProps['color'] => {
  switch (type) {
    case CardTypes.Image:
    case CardTypes.Upload:
    case CardTypes.Countdown:
    case CardTypes.Fact:
      return 'violet';
    case CardTypes.Embed:
    case CardTypes.Video:
    case CardTypes.Carousel:
      return 'teal';
    default:
      return 'blue';
  }
};
