import { AdminLiveApi } from '@/admin/data/AdminApi';
import { Administrator } from '@/admin/models/Administrator';
import { AdministratorRoles } from '@/admin/models/AdministratorRoles';
import { AdministratorSearch } from '@/admin/models/AdministratorSearch';
import { Guid } from '@komo-tech/core/models/Guid';
import { buildUrl } from '@komo-tech/core/utils/url';

export interface CheckEmailApiResponse {
  administratorId?: Guid;
  email?: string;
  isAdminVerified?: boolean;
  exists: boolean;
  error?: string;
}

export interface InviteAdminApiRequest {
  email: string;
  name: string;
  role: AdministratorRoles;
}

export interface InviteAdminApiResponse {
  email: string;
  emailSent: boolean;
  roleAdded: boolean;
}

export class AdminAdministratorService {
  public static cacheKeys = {
    admin: (id?: Guid) => ['admin', id?.toString()],
    search: () => ['admins-search']
  };
  public static getAdministratorFromSessionAsync = async (sessionId: Guid) =>
    new Administrator(
      await AdminLiveApi.get(`administrators/sessions/${sessionId}`)
    );

  public static searchAdministratorsAsync = async () => {
    const response =
      await AdminLiveApi.get<AdministratorSearch[]>('administrators');

    return response.map((x) => new AdministratorSearch(x));
  };

  public static updateAdministratorAsync = async (
    administrator: Administrator
  ) => {
    return new Administrator(
      await AdminLiveApi.put(
        `administrators/${administrator.id.toString()}`,
        administrator
      )
    );
  };

  public static deleteAdministratorAsync = async (id: Guid) => {
    return await AdminLiveApi.delete(`administrators/${id}`);
  };

  public static checkEmailAsync = (email: string) => {
    return AdminLiveApi.post<CheckEmailApiResponse>(
      buildUrl('administrators/check-email', { email })
    );
  };

  public static inviteAsync = async (request: InviteAdminApiRequest) => {
    return await AdminLiveApi.post<InviteAdminApiResponse>(
      'administrators/invite',
      request
    );
  };

  public static revokeInviteAsync = async (id: Guid) => {
    return AdminLiveApi.delete(`administrators/revoke/${id}`);
  };
}
