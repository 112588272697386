import { useQuery, useQueryClient } from '@tanstack/react-query';

import { usePagination } from '@/common/components/Pagination';
import { useQueryEvents } from '@/common/hooks/useQueryEvents';
import { Page } from '@komo-tech/core/models/Page';
import { AdminWorkspace } from '@/common/models/workspaces/Admin/AdminWorkspace';

import { AdminWorkspaceActions } from '../_actions';
import { AdminWorkspaceCacheKeys } from '../_cacheKeys';
import { AdminWorkspaceSearchRequest } from '../_types';

const PageSize = 50;

interface SearchProps
  extends Omit<AdminWorkspaceSearchRequest, 'take' | 'skip'> {
  pageSize?: number;
}

export const useWorkspacesSearch = ({
  pageSize,
  ...rest
}: SearchProps = {}) => {
  const queryClient = useQueryClient();
  const pagination = usePagination(pageSize || PageSize);

  const cacheKey = AdminWorkspaceCacheKeys.searchWorkspaces({
    take: pagination.take,
    skip: pagination.skip,
    ...rest
  });

  const query = useQuery(
    cacheKey,
    async () =>
      AdminWorkspaceActions.searchAsync({
        take: pagination.take,
        skip: pagination.skip,
        ...rest
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError: (err) => console.error(err)
    }
  );

  useQueryEvents(query, {
    onSuccess: (p) => pagination.setDefaults(p.items.length, p.totalItems)
  });

  return {
    hasData: !!query?.data?.items?.length,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
    data: query.data,
    pagination,
    cacheKey,
    invalidateAsync: () => queryClient.invalidateQueries(cacheKey),
    setData: (data: Page<AdminWorkspace>) =>
      queryClient.setQueryData(cacheKey, data)
  };
};
